export interface MenuItemEntry extends MenuItem {
  title?: string;
  items?: MenuItem[];
}

interface MenuItem {
  name?: string;
  link?: string;
  icon: string;
}

export const menuItems: MenuItemEntry[] = [
  { name: "Dashboard", link: "dashboard", icon: "dashboard" },
  {
    title: "Affiliates",
    items: [
      {
        name: "Offers",
        link: "affiliates",
        icon: "offers",
      },
      {
        name: "Transactions",
        link: "affiliate-transactions",
        icon: "affiliateTransactions",
      },
      { name: "Ad Banners", link: "ad-banners", icon: "adBanners" },
      {
        name: "Missing Transactions",
        link: "missing-transactions",
        icon: "missingTransactions",
      },
    ],
    icon: "affiliates",
  },
  {
    name: "BTC Purchases",
    link: "btc-purchases",
    icon: "bitcoin",
  },
  {
    title: "Gift Cards",
    items: [
      {
        name: "Offers",
        link: "gift-cards",
        icon: "offers",
      },
      {
        name: "Transactions",
        link: "gift-card-transactions",
        icon: "giftCardTransactions"
      },
      {
        name: "Bags",
        link: "gift-card-bags",
        icon: "giftCardBags",
      },
      {
        name: "Networks",
        link: "gift-card-networks",
        icon: "wireless",
      },
    ],
    icon: "giftCards",
  },
  {
    title: "Partners",
    items: [
      { name: "Pending Partners", link: "pending-partners", icon: "pending"},
      { name: "Approved Partners", link: "partners", icon: "approvedPartners" },
      { name: "Premium", link: "premium-accounts", icon: "premiumAccounts" },


    ],
    icon: "partners"
  },
  {
    title: "CLO",
    items: [
      {
        name: "Transactions",
        link: "clo-transactions",
        icon: "cloTransactions",
      },
      { name: "Brands & Campaigns", link: "clo", icon: "campaigns" },
      { name: "Billing", link: "billing", icon: "billing" },
      
    ],
    icon: "clo",
  },
  { name: "Travel", link: "travel", icon: "travel" },
  {
    title: "Users",
    items: [
      { name: "User Profile", link: "user-profile", icon: "userProfile" },
      {
        name: "Promotion Amounts",
        link: "promotions-amounts",
        icon: "promotionsAmounts",
      },
      { name: "Decrypt", link: "decrypt", icon: "decrypt" },
      {
        name: "Manual Transactions",
        link: "manual-transactions",
        icon: "manual",
      },
      { name: "Interac", link: "interac", icon: "interac" },
    ],
    icon: "users",
  },
  {name: "Broker Transactions", link:"broker-transactions", icon: "brokerTransactions"},
  { name: "Wallet Transactions", link: "wallet-transactions", icon: "walletTransactions"},
  { name: "Referrals", link: "referrals", icon: "referrals"},
  {
    name: "BTC Owed",
    link: "btc-owed-reporting",
    icon: "bitcoin",
  },
  { name: "Inactive Users", link: "inactive-users", icon: "zombie"},
  {
    name: "Health",
    link: "health",
    icon: "health",
  },
  {
    name: "Security",
    link: "security",
    icon: "security",
  },
  { name: "Storyly", link: "storyly", icon: "storyly" },
];
