import * as Modules from "./modules";

export type PageProps = {
  variant:
    | "ad-banners"
    | "affiliate-transactions"
    | "affiliates"
    | "billing"
    | "btc-purchases"
    | "btc-owed-reporting"
    | "clo-transactions"
    | "clo"
    | "decrypt"
    | "dashboard"
    | "gift-cards"
    | "gift-card-transactions"
    | "gift-card-bags"
    | "gift-card-networks"
    | "interac"
    | "inactive-users"
    | "manual-transactions"
    | "missing-transactions"
    | "partners"
    | "pending-partners"
    | "promotions-amounts"
    | "health"
    | "storyly"
    | "travel"
    | "user-profile"
    | "premium-accounts"
    | "broker-transactions"
    | "wallet-transactions"
    | "security"
    | "referrals";
};

export const Page = (props: PageProps): JSX.Element => {
  switch (props.variant) {
    case "ad-banners":
      return <Modules.AdBanners />;
    case "affiliate-transactions":
      return <Modules.AffiliateTransactions />;
    case "affiliates":
      return <Modules.Affiliates />;
    case "billing":
      return <Modules.Billing />;
    case "btc-owed-reporting":
      return <Modules.BTCOwedReporting />;
    case "btc-purchases":
      return <Modules.BTCPurchases />;
    case "clo-transactions":
      return <Modules.CLOTransactions />;
    case "clo":
      return <Modules.CLO />;
    case "dashboard":
      return <Modules.Dashboard />;
    case "decrypt":
      return <Modules.Decrypt />;
    case "gift-cards":
      return <Modules.GiftCards />;
    case "gift-card-transactions":
      return <Modules.GiftCardTransactions />;
    case "gift-card-bags":
      return <Modules.GiftCardBags />;
    case "gift-card-networks":
      return <Modules.GiftCardNetworks />;
    case "interac":
      return <Modules.Interac />;
    case "inactive-users":
      return <Modules.InactiveUsers />;
    case "manual-transactions":
      return <Modules.ManualTransactions />;
    case "missing-transactions":
      return <Modules.MissingTransactions />;
    case "partners":
      return <Modules.Partners />;
    case "pending-partners":
      return <Modules.PendingPartners />;
    case "promotions-amounts":
      return <Modules.PromotionsAmounts />;
    case "health":
      return <Modules.RiskManagement />;
    case "storyly":
      return <Modules.Storyly />;
    case "travel":
      return <Modules.Travel />;
    case "user-profile":
      return <Modules.UserProfile />;
    case "premium-accounts":
      return <Modules.PremiumAccounts />;
    case "broker-transactions":
      return <Modules.BrokerTransactions />;
    case "wallet-transactions":
      return <Modules.WalletTransactions />;
    case "referrals":
      return <Modules.Referrals />;
    case "security":
      return <Modules.Security />;  
    default:
      throw new Error(`[SIGN UP ERROR]: Invalid variant provided`);
  }
};
