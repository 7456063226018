import { Navigate, Routes, Route } from "react-router-dom";

import { Layout } from "@layouts";
import { Page } from ".";

export const AuthenticatedRouter = (): JSX.Element => {
  return (
    <Layout variant="authenticated">
      <Routes>
        <Route path="ad-banners" element={<Page variant="ad-banners" />} />
        <Route
          path="affiliate-transactions"
          element={<Page variant="affiliate-transactions" />}
        />
        <Route path="affiliates" element={<Page variant="affiliates" />} />
        <Route path="billing" element={<Page variant="billing" />} />
        <Route
          path="btc-owed-reporting"
          element={<Page variant="btc-owed-reporting" />}
        />
        <Route
          path="btc-purchases"
          element={<Page variant="btc-purchases" />}
        />
        <Route
          path="clo-transactions"
          element={<Page variant="clo-transactions" />}
        />
        <Route path="clo" element={<Page variant="clo" />} />
        <Route path="dashboard" element={<Page variant="dashboard" />} />
        <Route path="decrypt" element={<Page variant="decrypt" />} />
        <Route path="gift-cards" element={<Page variant="gift-cards" />} />
        <Route
          path="gift-card-transactions"
          element={<Page variant="gift-card-transactions" />}
        />
        <Route
          path="gift-card-bags"
          element={<Page variant="gift-card-bags" />}
        />
        <Route
          path="gift-card-networks"
          element={<Page variant="gift-card-networks" />}
        />
        <Route path="interac" element={<Page variant="interac" />} />
        <Route path="inactive-users" element={<Page variant="inactive-users" />} />
        <Route
          path="manual-transactions"
          element={<Page variant="manual-transactions" />}
        />
        <Route
          path="missing-transactions"
          element={<Page variant="missing-transactions" />}
        />
        <Route path="partners" element={<Page variant="partners" />} />
        <Route
          path="pending-partners"
          element={<Page variant="pending-partners" />}
        />
        <Route
          path="promotions-amounts"
          element={<Page variant="promotions-amounts" />}
        />
        <Route path="health" element={<Page variant="health" />} />
        <Route path="storyly" element={<Page variant="storyly" />} />
        <Route path="travel" element={<Page variant="travel" />} />
        <Route path="user-profile" element={<Page variant="user-profile" />} />
        <Route
          path="premium-accounts"
          element={<Page variant="premium-accounts" />}
        />
        <Route
          path="broker-transactions"
          element={<Page variant="broker-transactions" />}
        />
        <Route
          path="wallet-transactions"
          element={<Page variant="wallet-transactions" />}
        />
        <Route path="referrals" element={<Page variant="referrals" />} />
        <Route path="security" element={<Page variant="security"/>}/>
        <Route path="*" element={<Navigate to="authenticated/dashboard" />} />
      </Routes>
    </Layout>
  );
};
